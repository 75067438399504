<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h6>
        {{ `${$t('fields.result')} (${requests.length === selectedLimit ? requests.length + "+" : requests.length})` }}
      </h6>
    </div>
    <b-row class="mb-3">
      <b-col>
        <LimitSelect v-model="selectedLimit"/>
        <span v-if="isFetching" class="text-muted">กำลังโหลด...</span>
      </b-col>
      <b-col v-if="isOwner" cols="3">
        <MasterSelectInput
            :value="selectedMaster"
            hide-label
            @update="onMasterIdChange"
        />
      </b-col>
      <b-col v-if="isOwner || isMaster" cols="3">
        <AgentsSelectInput
            :master-id="selectedMaster"
            :value="selectedAgent"
            hide-label
            @update="onAgentIdChange"
        />
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
        :fields="type==='deposit'? depositFields: withdrawFields"
        :items="requests"
        responsive
        show-empty
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(transactionDateTime)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(createdAt)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(fromAccountNumber)="data">
        <b-media vertical-align="center">
          <template #aside>
            <BankIcon :bank-code="data.item.fromBankCode"/>
          </template>
          {{ data.item.fromAccountNumber }}<br>
          {{ data.item.fromAccountName}}
        </b-media>
      </template>
      <template #cell(userUsername)="data">
        <component :is="$allowPermission('agent:manage.user') && type==='withdraw' && data.item.userId ? 'a' : 'span'" :href="`/players/detail/${data.item.userId}`" target="_blank">
          <b-media vertical-align="center">
            {{ data.value.substring(0, 3) + 'xxx' + data.value.substring(6, 10) }}<br>
          </b-media>
        </component>
      </template>
      <template #cell(toBankAccountNumber)="data">
        <component :is="$allowPermission('agent:manage.user') && type==='withdraw' && data.item.userId ? 'a' : 'span'" :href="`/players/detail/${data.item.userId}`" target="_blank">
          <b-media vertical-align="center">
            <template #aside>
              <BankIcon :bank-code="data.item.toBankCode"/>
            </template>
            {{ data.value }}<br>
            {{ data.item.userFirstname }} {{ data.item.userLastname }}
          </b-media>
        </component>
      </template>
      <template #cell(toAccountNumber)="data">
        <b-media vertical-align="center">
          <template #aside>
            <BankIcon :bank-code="data.item.toBankCode"/>
          </template>
          {{ data.value }}<br>
          {{ data.item.toAccountName}}
        </b-media>
      </template>
      <template #cell(transactionChannelCode)="data">
        <b-badge>
          <template v-if="data.item.transactionChannelDescription">
            {{ data.item.transactionChannelDescription || '-' }}
            ({{ data.value || '-' }})
          </template>
          <template v-else>
            {{ data.value || '-' }}
          </template>
        </b-badge>
      </template>
      <template #cell(afterBalance)="data">
        <b-badge class="badge-soft-danger">
          {{ data.item.beforeBalance | currency }}
        </b-badge><br>
        <b-badge class="badge-soft-success">
          {{ data.value | currency }}
        </b-badge>
      </template>
      <template #cell(remain)="data">
        {{ displayRemainAmount(data.item) }}
      </template>
      <template #cell(action)="data">
        <b-button size="sm" type="button" variant="danger" @click="cancel(data.item.id)">
          ยกเลิก
        </b-button>
      </template>

      <template #empty="">
        <p class="text-center text-muted">{{ $t('messages.nothing_here') }}</p>
      </template>
    </b-table>
    <!-- pagination -->
    <SimplePagination :has-more-page="pagination.itemCount >= selectedLimit" :per-page="limit" @update="(val) => currentPage = val"/>
  </b-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  name: 'ProcessingTransactions',
  components: {
    SimplePagination: () => import('@components/commons/simple-pagination'),
    MasterSelectInput: () =>
        import('@components/master/master-select-input'),
    AgentsSelectInput: () =>
        import('@components/agents/agents-select-input'),
  },
  props: {
    type: {
      type: String,
      default: 'deposit',
    },
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      selectedTxn: {},
      depositFields: [
        '#',
        {
          key: 'transactionChannelCode',
          label: 'ช่องทาง',
        },
        {
          key: 'fromAccountNumber',
          label: 'บัญชีโอน',
        },
        {
          key: 'toAccountNumber',
          label: 'บัญชีรับ',
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
        },
        {
          key: 'afterBalance',
          label: 'คงเหลือ (ก่อน-หลัง)',
          class: 'text-right',
        },
        {
          key: 'transactionDateTime',
          label: 'เวลาฝาก',
        },
      ],
      withdrawFields: [
        '#',
        {
          key: 'userUsername',
          label: 'ยูสเซอร์เนม',
        },
        {
          key: 'toBankAccountNumber',
          label: 'บัญชี'
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
        },
        {
          key: 'remark',
          label: 'หมายเหตุ'
        },
        {
          key: 'createdAt',
          label: 'เวลาแจ้ง',
        },
        {
          key: 'action',
          label: 'จัดการ'
        }
      ],
      rejectForm: { txnId: '', detail: '', isReturnCredit: false },
      approveType: 'adjust',
      selectedTxnId: '',
      selectedMaster: '',
      selectedAgent: '',
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.deposit.isFetchingDepositProcessing || state.withdraw.isFetchingWithdrawProcessing,
    }),
    ...mapGetters(['isOwner', 'isMaster', 'depositProcessing', 'withdrawProcessing']),
    requests() {
      return this.type === 'deposit'? this.depositProcessing.items || []: this.withdrawProcessing.items
    },
    pagination() {
      return this.type === 'deposit'? this.depositProcessing.meta : this.withdrawProcessing.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
  },
  watch: {
    currentPage(val) {
      if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions([
      'fetchDepositProcessing',
      'fetchWithdrawProcessing',
      'cancelWithdraw'
    ]),
    cancel(id) {
      this
          .$bvModal
          .msgBoxConfirm('หากยกเลิก รายการจะถูกย้อนกลับไปหน้าดำเนินการ ยืนยันที่จะยกเลิกใช่หรอไม่?')
          .then((result) => {
            if(result) {
              this.cancelWithdraw(id)
            }
          })
    },
    fetchData() {
      if (this.type === 'deposit') {
        this.fetchDepositProcessing({
          limit: this.selectedLimit,
          page: this.currentPage,
          search: '',
          masterId: this.selectedMaster,
          agentId: this.selectedAgent,
        })
      } else {
        this.fetchWithdrawProcessing({
          limit: this.selectedLimit,
          page: this.currentPage,
          search: '',
          masterId: this.selectedMaster,
          agentId: this.selectedAgent,
        })
      }
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.$emit('on-agent-change', agentId)
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.$emit('on-master-change', masterId)
      this.fetchData()
    },
    displayRemainAmount(txn) {
      const remain = (Number(txn.beforeBalance) - Number(txn.afterBalance));
      return remain.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
  }
}
</script>
